import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { LinkBar, LinkSubBar } from '../components'
import { UploadReport, UploadPage, UploadPdf } from '../pages/Upload'
import { list2 } from '../services';

const options = [
    { label: 'Report', name: 'upload/report' },
    { label: 'Balance', name: 'upload/balance' },
    { label: 'Liquid Balance', name: 'upload/balance_liquid' },
    { label: 'Pdf', name: 'upload/pdf' },
]

export const Upload = () => {
    const [tab, setTab] = useState(options[0])
    // useEffect(() => {
    //     list2('user')
    // }, [])
    const onSelectTab = event => {
        setTab(options.find(option => option.name === event))
    }
    return (
        <div>
            <LinkBar options={options} callback={onSelectTab} />
            {tab.children && <LinkSubBar category={tab.name} options={tab.children} />}
            <Switch>
                <Route path="/upload/report" children={<UploadReport />} />
                <Route path="/upload/pdf" children={<UploadPdf />} />
                <Route path="/upload/:type" children={<UploadPage />} />
                <Redirect to="/upload/report" />
            </Switch>
        </div>
    )
}